<template>
  <div>
    <loader v-show="loading" />
    <header-one />
    <!-- <header-two :style_2="true" /> -->
    <main>
      <slot />
    </main>
    <footer-one />
    <!-- <footer-one :primary_style="true" /> -->
    <back-to-top />
    <!-- <modal-product /> -->
  </div>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/pinia/useAuthStore";
import { useCheckoutStore } from "@/pinia/useCheckoutStore";
import { useCurrencyStore } from "@/pinia/useCurrencyStore";
import { useHomeStore } from "@/pinia/useHomeStore";
import { useUtilityStore } from "@/pinia/useUtilityStore";
import { useLoader } from "@/pinia/useLoader";
import { storeToRefs } from "pinia";
const { getHomeData, getBrands, getContactInfo, getPages } = useHomeStore();
const { getAllCurrencies } = useCurrencyStore();
const { getCheckoutData } = useCheckoutStore();
const { initializeLanguage } = useUtilityStore();
const { getProfile } = useAuthStore();

const { getCategories } = useHomeStore();
const { loading } = storeToRefs(useLoader());
const { startLoading, stopLoading } = useLoader();
const route = useRoute();
// watch(
//   () => route.path,
//   () => {
//     startLoading();
//     setTimeout(() => {
//       stopLoading();
//     }, 1000);
//   }
// );
//startLoading();
await Promise.all([
  // getBrands(),
  getAllCurrencies(),
  getCategories(),
  // getCheckoutData(),
  // initializeLanguage(),
  // getContactInfo(),
  // getPages(),
]).then(() => {
  // //stopLoading();
});
const { locale } = useI18n();
const htmlAttrs = computed(() => ({
  dir: locale.value === "ar" ? "rtl" : "ltr",
  lang: locale.value,
}));
watch(
  () => locale.value,
  async (newLocale) => {
    await Promise.all([getAllCurrencies(), getCategories()]);
  }
);
useHead(() => ({
  htmlAttrs,
  link: [
    {
      rel: "canonical",
      href: "https://swift-ecommerce.urusglobal.com" + route.path,
    },
  ],
}));
</script>
